import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService, ApiService } from '@app/core';

import { ActivityType } from '../model';

export const ActivityTypeService = new InjectionToken<BaseFeatureModuleService<ActivityType>>('ActivityTypeService');

export function activityTypeSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<ActivityType> {
  return new BaseFeatureModuleService<ActivityType>(apiSvc, 'activity-type',
    { getItems: { }, getItem: { } });
}
