import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';
import { AuthModule } from './auth';
import { CoreModule, SideNavComponent, MOMENT_FORMATS } from './core';
import { AppRoutingModule } from './app-routing.module';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';

import { OwlDateTimeModule, OwlDateTimeIntl, OWL_DATE_TIME_LOCALE, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';

// import ngx-translate and the http loader
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SettingsProvider } from '@app/core/app-load/settings.provider';
import { DefaultIntl } from './core/service/defaultIntl';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TranslateUniversalLoader } from './core/app-load/translate-universal.loader';
import {OwlMomentDateTimeModule} from 'ng-pick-datetime-moment';
import { AvatarComponent } from './components/avatar/avatar.component';
import {ComponentsModule} from '@app/components/components.module';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateUniversalLoader(http);
}

export function settingsProviderFactory(provider: SettingsProvider) {
  return () => provider.load();
}
@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    UiSwitchModule.forRoot({
      size: 'small'
    }),
    NgProgressModule,
    NgProgressHttpModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    CoreModule,
    AuthModule,
    AppRoutingModule
  ],
  providers: [
    SettingsProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: settingsProviderFactory,
      deps: [SettingsProvider, HttpClient], multi: true
    },
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: MOMENT_FORMATS.MY_NATIVE_FORMATS
    },
    {
      provide: OwlDateTimeIntl,
      useClass: DefaultIntl
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
