import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Subscription } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';

import { SideNavService } from '../sidenav/sidenav.service';
import { AuthService } from '../auth';

import { Router } from '@angular/router';
import { WINDOW } from '../../core/window-factory';
import { SettingsService, UtilService } from '../service';
import { CompanySettings, LanguagesSettings, UserProfile } from '../model';
import { DropdownHeaderService } from '../template/dropdown-header/dropdown-header.service';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { UserProfileService } from '../service/generals';
import { CFG } from '../config';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    companySettings: CompanySettings;
    languagesSettings: LanguagesSettings;
    isAuthorized = false;
    progressRef: NgProgressRef;
    isRunningProgress = false;
    routeProblemsCnt = 0;
    isViewRouteService = false;
    // profile image
    profile: UserProfile;
    profileLetters = 'Un';
    profileColor = '#263238';
    // Subscriptions
    userProfileSubscr: Subscription;

    constructor(public authSvc: AuthService,
        private sidenavSvc: SideNavService,
        private dropdownHeaderSvc: DropdownHeaderService,
        private settingsSvc: SettingsService,
        private router: Router,
        private ngProgress: NgProgress,
        private utilSvc: UtilService,
        private userProfileSvc: UserProfileService,
        private localize: LocalizeRouterService,
        @Inject(WINDOW) public window: Window) {
    }

    ngOnInit() {
        this.progressRef = this.ngProgress.ref();
        // In that case when we have token we can get the company settings
        // Somethimes happened that we dont have token, that means we need to login in
        // and in this case the company setting will be an empty object and not null!
        this.companySettings = this.settingsSvc.getCompanySettsings();
        this.languagesSettings = this.settingsSvc.getLanguagesSettings();
        this.routeProblemsCnt = this.settingsSvc.getRouteProblemsCnt();
        this.isViewRouteService = this.settingsSvc.userHasPermission('viewRouteService');

        // Showing the harmurger and logo by the authorize movement
        this.authSvc.isAuthenticated$.subscribe((isAuthorized) => {
            this.isAuthorized = isAuthorized;
        });

        // If the user logged in, then we have tha company settings from sessoin call
        // The subject has fired and we got the company settings data
        this.settingsSvc.settingsHasChangedSubject.subscribe(() => {
            this.companySettings = this.settingsSvc.getCompanySettsings();
            this.languagesSettings = this.settingsSvc.getLanguagesSettings();
            this.routeProblemsCnt = this.settingsSvc.getRouteProblemsCnt();
            this.isViewRouteService = this.settingsSvc.userHasPermission('viewRouteService');
        });

        // Progress bar events (optional)
        this.progressRef.started.subscribe(() => {
            this.isRunningProgress = true;
        });
        this.progressRef.completed.subscribe(() => {
            this.isRunningProgress = false;
        });

        this.userProfileSubscr = this.userProfileSvc.profile$.subscribe(data => {
            this.profile = data;

            if (this.profile) {
                sessionStorage.setItem('userProfileId', String(this.profile.Id));
                const firstLettersSum = UtilService.getFirstLettersAsciiSum(`${this.profile.FirstName} ${this.profile.LastName}`);
                this.profileLetters = this.utilSvc.getFirstLetters(`${this.profile.FirstName} ${this.profile.LastName}`);
                this.profileColor = CFG.colors[+firstLettersSum % CFG.colors.length];
            }
        });
    }

    onClickHamburgerBtn() {
        this.sidenavSvc.open();
    }

    onOpenDropdownHeader() {
        this.dropdownHeaderSvc.open();
    }

    useLanguage(language: string) {
        let url: any = this.router.url.split('/');
        url[1] = language;
        url = url.join('/');
        this.window.location.href = url;
    }

    goToServieList() {
        this.router.navigate([this.localize.translateRoute('/app/route/service/list')]);
    }
}
